<script setup lang="ts">
import type {UserBanner} from '~/types/UserBanner'
import {useDomainDataStore} from "~/stores/domain";

const domainDataStore = useDomainDataStore()
const { locale } = useI18n()

interface Props {
  banner: UserBanner,
}

const props = withDefaults(defineProps<Props>(), {})

const colorClasses = computed(() => {
  if (props.banner.level === 'error') {
    return '!bg-red !text-white'
  }

  return '!bg-gold !text-white'
})
</script>

<template>
  <AtomsIsland :class="['grow-0 p-2 text-center', colorClasses]">
    <p v-if="banner.type === 'pending'">
      {{ $t('alert_banner.account_pending') }}
    </p>
    <p v-else>
      {{ $t(`alert_banner.${banner.type}`) }}
    </p>
  </AtomsIsland>
</template>