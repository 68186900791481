<script setup lang="ts">
import {useDomainDataStore} from "~/stores/domain"

const domainData = useDomainDataStore()
</script>

<template>
  <AtomsPageFader />
  <div class="min-h-screen flex flex-col bg-domain-dark p-bento gap-bento" :class="domainData.domainClass">
    <MoleculesHeader />
    <MoleculesUserBanners />
    <LayoutsMediaBrowser v-if="$route.meta.isMediaBrowser">
      <slot />
    </LayoutsMediaBrowser>
    <slot v-else />
    <MoleculesFooter />
  </div>
</template>